import VueLib from 'vue'
import merge from 'lodash.mergewith'
import * as Sentry from '@sentry/browser'

import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

// eslint-disable-next-line require-await
export default async function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    release:"1.7.15",
    dsn:"https:\u002F\u002Fa324cc381cdafd4ca7ce5fff1ba0ab84@sentry.aws.delfi.net\u002F53",
    environment:"production",
    sampleRate:0.1,
    tracesSampleRate:0.1,
    beforeSend:(event) => {
        if (event.level === 'info') {
          return null;
        }

        return event;
      },
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe(),
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false}}),
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
